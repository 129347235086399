/*
* @Author: axeldelcros
* @Date:   2017-01-12 22:28:43
* @Last Modified by:   axeldelcros
* @Last Modified time: 2017-02-09 22:00:43
*/
body.is-open{overflow: hidden;}

header {
	width: 100%;
	background-color: #fff;
	position: fixed;
	top: 0;
	padding: 5px 0 5px 0;
	z-index: 999999;
}

#uiview {
	min-height: 100vh;
}

#uiview section {
	min-height: 100vh;
	padding-bottom: 100px;
	display: -webkit-box;
	/* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;
	/* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;
	/* TWEENER - IE 10 */
	display: -webkit-flex;
	/* NEW - Chrome */
	display: flex;
}

.content {
	padding: 150px 10% 100px 10%;
	-moz-flex-direction: column;
	-webkit-flex-direction: column;
	-webkit-box-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	/* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: 1;
	/* OLD - Firefox 19- */
	-webkit-flex: 1;
	/* Chrome */
	-ms-flex: 1;
	/* IE 10 */
	flex: 1;
	/* NEW, Spec - Opera 12.1, Firefox 20+ */
	overflow: hidden;
}

.sectiontitle {
	text-align: center;
	color: #888;
	/*font-style: italic;*/
	font-size: 50px;
}

div.section {
	display: -webkit-box;
	/* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;
	/* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;
	/* TWEENER - IE 10 */
	display: -webkit-flex;
	/* NEW - Chrome */
	display: flex;
	position: relative;
	min-height: 100vh;
	z-index: 1;
}

div.section::after{
	content: "";
	display: block;
	position: absolute;
	height: 1px;
	width: 70%;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(0,0,0,.14);
}

footer {
	box-sizing: border-box;
	position: fixed;
	padding:1.2rem 3rem;
	left: 0;
	bottom: 0;
	background-color: #efefef;
	width: 100%;
	z-index: 2;
}

footer ul {
	float: right;
	margin-top: 0;
	margin-bottom: 0;
	display: table-row;
}

footer ul li {
	display: table-cell;
	padding-left: 1rem;
	vertical-align: middle;
}

footer ul li a {
	color: rgba(100, 100, 100, 0.8);
	text-shadow: 1px 4px 6px #AEAEAE, 0 0 0 #000, 1px 4px 6px #AEAEAE;
}

footer ul li a:hover {
	text-shadow: 8px 8px 6px #AAA, 0 0 0 #000, 8px 8px 6px #AAA;
}

@media all and (max-width: 768px){
	.sectiontitle{font-size: 40px;}
	.baseline{font-size:12pt;}
	#uiview section{ min-height: 100vh; }
	.content{padding: 50px 1% 100px 1%}
	footer {display: none;}
}

@media all and (max-width: 992px) {
	header {
		height: 50px;
		z-index: 999999;
		text-align: center;
		font-size: 20pt;
	}
	/*header.is-open{height:100vh;}*/
	header ul.socialnetwork {
		height: 62px;
		margin-top: 0;
	}
	header ul.socialnetwork li {
		float: left;
	}
	header ul.socialnetwork li a {
		font-size: 27pt;
		text-shadow: 1px 4px 6px #FEFEFE, 0 0 0 #000, 1px 4px 6px #FEFEFE;
		color: rgba(100, 100, 100, 0.8);
	}


	#uiview section{ min-height: 100vh; }
	#uiview section#home {height: 80vh;min-height: 80vh;}
}



@media all and (min-width: 768px){
	header{height: 80px;}
	header{padding: 10px 0 10px 0}
}
