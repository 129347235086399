.toast-top-full-width {
    margin-top: 120px;
}
#toast-container.toast-top-full-width > div {
    width: 90%;
    max-width: 700px;
    text-align: left;
    font-size: 1em;
}
.recaptchaWrapper {
    position: relative;
    display:block;
    width:calc(100% - 15px);
    margin: auto;
}
@media all and (max-width: 991px) {
    .recaptcha > div {
        transform: scale(0.7);
    }
}
.recaptcha div {
    margin: 0 auto;
}
