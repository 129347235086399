/*
* @Author: axeldelcros
* @Date:   2017-01-11 23:09:55
* @Last Modified by:   axeldelcros
* @Last Modified time: 2017-02-09 22:00:36
*/
html,
body {
	margin: 0;
	padding: 0;
	font-family: "futura-lt", "futura", "helvetica-neue", helvetica, arial, sans-serif;
	color: #333;
	background-color: #fff;
	height: 100%;
	text-align: justify;
	-webkit-font-smoothing: subpixel-antialiased;
}

h1,h2,h3,h4,h5 {
	font-family: 'rounded-grotesk';
}
/*
h1{
	font-size: 3em;
}*/
h2{
	font-size: 2.5em;
}

.btn{
	padding: 10px 15px;
	display: inline-block;
	float: left;
	border-radius: 10px;
	text-align: center;
}

.btn:hover{
	cursor:pointer;
}

.btn-center{
	margin:0 auto;
}

.pointer:hover{
	cursor: pointer;
}

.logocolor,.logoblack{
	opacity: 1;
	-webkit-transition: opacity 300ms;
	transition: opacity 300ms;
}

.hide{
	opacity: 0;
	-webkit-transition: opacity 300ms;
	transition: opacity 300ms;
}

ul{
	-webkit-padding-start:0;
	padding: 0;
}

li{
	list-style: none;
	margin-left:10px;
}

.credits {
	margin-top: 100px;

	text-align: center;
	font-size: 0.8em;
}

.credits li {
	margin-left: 0;	
}

a{
	text-decoration:none;
	color:#555;
}
.red {
  color:#CD4442
}
.grey {
	color: #888;
}
.blue {
	color: #1686b8;
}
.footer {
	font-size: 0.8em;
}
.dark{
	background-color: #fff;
	color:#555;
	z-index: 2
}
.center{
	text-align: center;
}
.centerImage{
	margin: auto;
	max-width: 300px;
}
.pad-text{
	padding: 0 30px;
}
.block-content{
	margin-top: 75px;
}
.btn:hover{
	cursor:pointer;
}

.btn-center{
	margin:0 auto;
}
.pointer:hover{
	cursor: pointer;
}
ul.coc {
	padding: 0 30px;
}
ul.coc li {
	margin-bottom : 10px;
}
@media (min-width: 768px) {
	ul.coc li {
		margin-bottom : 0px;
	}
}