@font-face {
    font-family: 'neueeinstellung-light';
    src: url('../fonts/neueeinstellung/neueeinstellung-light-webfont.eot');
    src: url('../fonts/neueeinstellung/neueeinstellung-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/neueeinstellung/neueeinstellung-light-webfont.woff2') format('woff2'), url('../fonts/neueeinstellung/neueeinstellung-light-webfont.woff') format('woff'), url('../fonts/neueeinstellung/neueeinstellung-light-webfont.ttf') format('truetype'), url('../fonts/neueeinstellung/neueeinstellung-light-webfont.svg#webfontregular') format('svg');
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
}

@font-face {
    font-family: 'neueeinstellung-medium';
    src: url('../fonts/neueeinstellung/neueeinstellung-medium-webfont.eot');
    src: url('../fonts/neueeinstellung/neueeinstellung-medium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/neueeinstellung/neueeinstellung-medium-webfont.woff2') format('woff2'), url('../fonts/neueeinstellung/neueeinstellung-medium-webfont.woff') format('woff'), url('../fonts/neueeinstellung/neueeinstellung-medium-webfont.ttf') format('truetype'), url('../fonts/neueeinstellung/neueeinstellung-medium-webfont.svg#webfontregular') format('svg');
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
}

@font-face {
    font-family: 'neueeinstellung-regular';
    src: url('../fonts/neueeinstellung/neueeinstellung-regular-webfont.eot');
    src: url('../fonts/neueeinstellung/neueeinstellung-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/neueeinstellung/neueeinstellung-regular-webfont.woff2') format('woff2'), url('../fonts/neueeinstellung/neueeinstellung-regular-webfont.woff') format('woff'), url('../fonts/neueeinstellung/neueeinstellung-regular-webfont.ttf') format('truetype'), url('../fonts/neueeinstellung/neueeinstellung-regular-webfont.svg#webfontregular') format('svg');
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
}

@font-face {
    font-family: 'rounded-grotesk';
    src: url('../fonts/rounded-grotesk/rounded_grotesk.eot');
    src: url('../fonts/rounded-grotesk/rounded_grotesk.eot?#iefix') format('embedded-opentype'), url('../fonts/rounded-grotesk/rounded_grotesk.woff2') format('woff2'), url('../fonts/rounded-grotesk/rounded_grotesk.woff') format('woff'), url('../fonts/rounded-grotesk/rounded_grotesk.ttf') format('truetype'), url('../fonts/rounded-grotesk/rounded_grotesk.svg#webfontregular') format('svg');
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
}

@font-face {
    font-family: 'futura-lt';
    src: url('../fonts/futura-lt/futura-lt.eot');
    src: url('../fonts/futura-lt/futura-lt.eot?#iefix') format('embedded-opentype'), url('../fonts/futura-lt/futura-lt.woff2') format('woff2'), url('../fonts/futura-lt/futura-lt.woff') format('woff'), url('../fonts/futura-lt/futura-lt.ttf') format('truetype'), url('../fonts/futura-lt/futura-lt.svg#webfontregular') format('svg');
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
}