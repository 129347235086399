/*
* @Author: axeldelcros
* @Date:   2017-01-21 14:35:41
* @Last Modified by:   axeldelcros
* @Last Modified time: 2017-02-09 22:01:03
*/
#toplogo{
	float:left;
	width:250px;
	height: 69px;
	margin-top: 15px;
	margin-left: 50px;
	position: fixed;
}

.logohome{
	width: 70%;
	margin-left: 15%;
	margin-right: 15%;
	height: 50vh;
}

.logo-top-mobile{
	display: none;
	background-image: url('../img/top-logo-lightning-centered-2022.svg');
	width: 180px;
	height: 50px;
	background-repeat: no-repeat;
	background-size: 100%;
	float: left;
	margin-left: calc(50% - 90px);
	margin-top: 10px;
	z-index: 99999999999;
}

.navbarmenu{
	min-width: 20%;
	position: fixed;
}

.navbarmenu ul{
	display:table-row;
}

.navbarmenu ul li{
	display: table-cell;
	vertical-align: middle;
	margin: 10px;
	height:60px;
	padding:0 15px 5px 15px;
}

.navbarmenu ul li a{
	display: table-cell;
	height: 50px;
	vertical-align: middle;

	font-size: 18px;
	color:#555;
	text-decoration: none;
	text-align: center;
	max-width: 120px;
	padding-bottom: 5px;
	padding-top: 10px;
	border-bottom:2px solid rgba(130, 130, 130, 0);
	transition: all 500ms;
}

.navbarmenu ul li a:hover{
	border-bottom:2px solid rgba(130, 130, 130, 1);
	transition: all 500ms;
	cursor: pointer;
}

.navbarmenu ul li a.selected{
	border-bottom:2px solid rgba(130, 130, 130, 1);	
}

.nav-button{position: fixed;width:50px;height:50px;z-index: 99999999;right: 15px; display: none;}
.nav-button>span{border-radius:10%;position: absolute;top:50%;left: 50%;display:block;width:30px;height:4px;background-color:#555;transform: translateX(-50%) translateY(-50%);}
.nav-button>span:after, .nav-button>span:before{border-radius:10%;content: ''; position: absolute;top:0;left: 0; display: block;width: 30px;height: 4px;background-color: #555;transition:transform 0.5s ;}
.nav-button>span:before{transform: translateY(-10px);}
.nav-button>span:after{transform: translateY(10px);}
.nav-button:hover{cursor: pointer;}
.nav-button:hover>span:before{transform: translateY(-12px);}
.nav-button:hover>span:after{transform: translateY(12px);}
.nav-button{transition: transform 0.5s;}
.nav-button.is-open{transform: rotate(180deg);}
.nav-button.is-open>span{background-color: transparent;}
.nav-button.is-open>span:before{transform: translateY(0) rotate(45deg);}
.nav-button.is-open>span:after{transform: translateY(0) rotate(-45deg);}
.nav-button.is-open>span:before,.nav-button.is-open>span:after{transform-origin:50% 50%;}
.nav-button.is-open>span{transition: background-color 0.5s;}
.nav-menu-mobile{display:none;opacity:0;height:0;margin-top: 50px;transition: all ease-in-out 300ms;background-color: #fff;}
.nav-menu-mobile ul {
	padding-top: 20px;
}

.visible{
	opacity: 1;
	transition: opacity 0.3s linear;
}

.hidden{
	opacity: 0;
	transition: opacity 0.3s linear;
}

@media all and (max-width: 992px){
	@-webkit-keyframes scroll-down-arrow {
		0% {bottom: 10px;}
		50% {bottom: 15px;}
		100% {bottom: 10px;}
	}

	@keyframes scroll-down-arrow {
		0% {bottom: 10px;}
		50% {bottom: 20px;}
		100% {bottom: 10px;}
	}

	.logo-top-mobile{display: block;}

	.nav-button{display:block;}
	#toplogolink{display: none;}
}

@media all and (max-width: 992px){
	.nav-button{display:block;}
	.navbarmenu{display: none;}
	
	li {margin-left:0;}

	.nav-menu-mobile.is-open{display:block;opacity: 1;height:100vh;transition: all ease-in-out 300ms;}	
	.nav-menu-mobile.is-open li{ margin-bottom: 15px;}
	.nav-menu-mobile.is-open a.selected{
		border-bottom:2px solid rgba(130, 130, 130, 1);	
		margin-bottom:10px;/*text-decoration: underline;*/
	}
}