.logohome{
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
	height: 55vh;
}

@media all and (max-width: 992px){
	.logohome {
		width:90%;
		margin-top:5%;
		margin-right: 5%;
		margin-left: 5%;
	}
}

.baseline{
	font-weight: bold;
	font-size: 20pt;
	margin: 30px 20px;
	text-align: center;
}

@media all and (max-width: 768px) {
	.baseline{
		font-size:14pt;
	}
}

.green {
	color: green;
}

@-webkit-keyframes spaceship-move {
	0% {transform: translateY(0px) translateX(0px);}
	50% {transform: translateY(-5px) translateX(5px);}
	100% {transform: translateY(0px) translateX(0px);}
}

@keyframes spaceship-move {
	0% {transform: translateY(0px) translateX(0px);}
	50% {transform: translateY(-5px) translateX(5px);}
	100% {transform: translateY(0px) translateX(0px);}
}

.main.ng-enter {
	position: absolute;
	top: -100vh;
	transition: all ease 500ms;
}

.main.ng-enter-active{
	position: relative;
	top:0px;
	transition: all ease 500ms;
}

.spaceshipmove{
	animation: spaceship-move 1s ease-in-out infinite;
}